import router from './router'

// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
// NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach((to, from, next) => {
  const requireAuth = to.meta && to.meta.requireAuth
  // const hasLogin = localStorage.getItem('user_token')
  const hasLogin = true

  console.log(to)
  localStorage.setItem("menuIndex", String(to.meta.menuIndex))
  // window.onbeforeunload = function() {
  //   return false
  // }
  if (requireAuth) {
    // 需要登录
    if (hasLogin) {
      next()
    } else {
      // eslint-disable-next-line no-undef
      router.push({
        name: 'login'
      })
    }
  } else {
    next()
    return
  }
})

// ！！！TMD这里加了路由的话，有表单提交的页面刷新就会弹框，找半天。。。
// router.afterEach((to) => {
//   // onbeforeunload
//   const unloadEventList = ['']
//   if (unloadEventList.indexOf(to.name) !== -1) {
//     window.onbeforeunload = function(e) {
//       var event = e || window.event
//       return event && (event.returnValue = '关闭提示')
//     }
//   } else {
//     window.onbeforeunload = null
//   }
// })
