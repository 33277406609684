import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home.vue'
export const Layout = () => import("@/layout/index.vue");
export const LayoutAI = () => import("@/layout/index_content.vue");

const routeList: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    redirect: "/ai/chat",
    children: [
      {
        path: "/ai/chat",
        component: () => import("@/views/chat.vue"),
        name: "chat",
        meta: { title: "问答", requireAuth: true, menuIndex: '2' },
      },
    ],
  },
  {
    path: "/about",
    component: Layout,
    children: [
      {
        path: "/about",
        component: () => import("@/views/about.vue"),
        name: "about",
        meta: { title: "about", requireAuth: true },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: 'login'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register.vue'),
    meta: {
      title: 'register'
    }
  },
  {
    path: "/ai",
    component: Layout,
    children: [
      {
        path: "/ai/apps/set",
        component: () => import("@/views/apps/set.vue"),
        name: "apps-set",
        meta: { title: "简易配置", requireAuth: true, menuIndex: '1' },
      },
      {
        path: "/ai/apps/out",
        component: () => import("@/views/apps/out.vue"),
        name: "apps-out",
        meta: { title: "外部使用", requireAuth: true, menuIndex: '1' },
      },
      {
        path: "/ai/apps/log",
        component: () => import("@/views/apps/log.vue"),
        name: "apps-log",
        meta: { title: "对话日志", requireAuth: true, menuIndex: '1' },
      },
      {
        path: "/ai/llm/data",
        component: () => import("@/views/llm/data.vue"),
        name: "llm-data",
        meta: { title: "数据集", requireAuth: true, menuIndex: '3' },
      },
      {
        path: "/ai/llm/part",
        component: () => import("@/views/llm/part.vue"),
        name: "llm-part",
        meta: { title: "数据集内容", requireAuth: true, menuIndex: '3' },
      },
      {
        path: "/ai/llm/import",
        component: () => import("@/views/llm/import.vue"),
        name: "llm-import",
        meta: { title: "上传数据", requireAuth: true, menuIndex: '3' },
      },
      {
        path: "/ai/llm/setting",
        component: () => import("@/views/llm/setting.vue"),
        name: "llm-setting",
        meta: { title: "设置", requireAuth: true, menuIndex: '3' },
      },
      {
        path: "/ai/crawl/result",
        component: () => import("@/views/crawl/result.vue"),
        name: "crawl-result",
        meta: { title: "数据抓取结果", requireAuth: true, menuIndex: '4' },
      },
    ],
  },
  // {
  //   path: "/ai/llm",
  //   component: Layout,
  //   meta: { title: "知识库", requireAuth: true, menuIndex: '3' },
  //   children: [
  //     {
  //       path: "/ai/llm/data",
  //       component: () => import("@/views/llm/data.vue"),
  //       name: "llm-data",
  //       meta: { title: "数据集", requireAuth: true, menuIndex: '3' },
  //     },
  //     {
  //       path: "/ai/llm/part",
  //       component: () => import("@/views/llm/part.vue"),
  //       name: "llm-part",
  //       meta: { title: "数据集内容", requireAuth: true, menuIndex: '3' },
  //     },
  //     {
  //       path: "/ai/llm/import",
  //       component: () => import("@/views/llm/import.vue"),
  //       name: "llm-import",
  //       meta: { title: "上传数据", requireAuth: true, menuIndex: '3' },
  //     },
  //     {
  //       path: "/ai/llm/setting",
  //       component: () => import("@/views/llm/setting.vue"),
  //       name: "llm-setting",
  //       meta: { title: "设置", requireAuth: true, menuIndex: '3' },
  //     },
  //   ],
  // },
]


export const aiRoutes: RouteRecordRaw[] = [
  {
    path: "/ai",
    component: Layout,
    redirect: "/ai/apps",
    children: [
      {
        path: "/ai/apps",
        component: () => import("@/views/apps/apps.vue"),
        name: "apps",
        meta: { title: "应用", requireAuth: true, menuIndex: '1' },
      },
      {
        path: "/ai/chat",
        component: () => import("@/views/chat.vue"),
        name: "chat",
        meta: { title: "问答", requireAuth: true, menuIndex: '2' },
      },
      {
        path: "/ai/prompt",
        component: () => import("@/views/prompt/list.vue"),
        name: "prompt",
        meta: { title: "提示词", requireAuth: true, menuIndex: '5' },
      },
      {
        path: "/ai/model",
        component: () => import("@/views/model/list.vue"),
        name: "model",
        meta: { title: "模型管理", requireAuth: true, menuIndex: '6' },
      },
      {
        path: "/ai/llm",
        component: () => import("@/views/llm/llm.vue"),
        name: "llm",
        meta: { title: "知识库", requireAuth: true, menuIndex: '3' },
      },
      {
        path: "/ai/crawl",
        component: () => import("@/views/crawl/crawl.vue"),
        name: "crawl",
        meta: { title: "数据抓取", requireAuth: true, menuIndex: '4' },
      },
      {
        path: "/ai/user",
        component: () => import("@/views/user.vue"),
        name: "user",
        meta: { title: "用户", requireAuth: true },
      },
    ],
  },
];

let routes = [...routeList, ...aiRoutes]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

/**
 * 重置路由
 */
export function resetRouter() {
  router.replace({ path: "/login" });
}

export default router
