import axios from 'axios'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import { ElMessage, ElLoading } from 'element-plus'

// 创建一个loading实例
let loadingInstance: any

const req = axios.create({
  baseURL: 'https://qcopsapi.thvai.com/console/api',
  // baseURL: 'https://etopsapi.thvai.com/console/api',
  // baseURL: 'http://127.0.0.1:5173/console/api',
  // baseURL: 'http://192.168.2.108:5173/console/api',
  timeout: 60 * 1000, // 请求超时时间设置为30秒
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
})

const NETWORK_ERROR = '网络错误，请稍后重试'

/**
 * 请求拦截器
 */
req.interceptors.request.use((req) => {
  // 在请求开始时显示loading
  // loadingInstance = ElLoading.service({ fullscreen: true });
  // console.log('请求拦截器 =>', req)
  Nprogress.start()
  return req;
}, (error) => {
  Nprogress.done()
  return Promise.reject(error);
});

/**
 * 响应拦截器
 */
req.interceptors.response.use(function (res) {
  // 在请求结束时关闭loading
  // if (loadingInstance) {
  //   loadingInstance.close();
  // }
  // console.log('响应拦截器 =>', res)
  Nprogress.done()
  return res.data
  if (res.data.errno == 0) {
    return res.data
  } else {
    // ElMessage.error((NETWORK_ERROR))
    return Promise.reject(NETWORK_ERROR)
  }
});

export default req